<template>
  <div class="monitoring-items-upload mt-4">
    <b-card>
      <b-row>
        <div class="col-md-2">
          <b-form-select id="currency" class="mb-2" v-model="status" :options="statusOptions" required
            @change="fetchItemProcess"></b-form-select>
        </div>
      </b-row>
      <b-row class="pb-2">
        <b-col cols="6">
            <input
              placeholder="Type Here to Search Audiobook"
              class="form-control"
              type="search"
              v-model="filter"
              @input="onSearchItem"
              v-on:keyup.enter="onSearchItem"
            />
            <small v-if="typing"> <em> typing ... </em> </small>
          </b-col>
        <b-col class="justify-content-end pt-1 d-flex align-items-end">
          <span id="button-reload" class="pr-2">
            <button class="btn btn-sm btn-dark" @click="reloadBtn">
              <i class="fa fa-refresh"></i>
            </button>
          </span>
          <span>
            Count :
            <strong class="col-sm-auto">{{ totalRows }}</strong>
          </span>
        </b-col>
      </b-row>
      <b-table 
        sticky-header="600px"
        :items="items"
        :fields="fields"
        :busy="isLoading"
        :isLoading="isLoading"
        show-empty
        responsive="xl"
        hover
        sort-icon-left
      >
        <template #cell(No)="row">
          {{ (currentPage - 1) * perPage + (row.index + 1) }}
        </template>
        <template #cell(name)="row">
          {{ row.item.items ? row.item.items.name : '' }}
          <strong>
            #{{ row.item.item_id }}
          </strong>
        </template>
        <template #cell(edition_code)="row">
          {{ row.item.items ? row.item.items.edition_code : '' }}
        </template>
        <template #cell(release_date)="row">
          {{ formatDate(row.item.items.release_date) }}
        </template>
        <template #cell(start_process)="row">
          {{ row.item.start_process_time ? formatDate(row.item.start_process_time) : 'Not Started' }}
        </template>
        <template #cell(end_process)="row">
          {{ row.item.end_process_time ?  formatDate(row.item.start_process_time) :'Not Started' }}
        </template>
        <template #cell(pic)="row">
          {{ row.item.pic_name }}
        </template>
        <template #cell(status)="row">
          <div>
            <span v-if="row.item.status == 10" class="badge badge-success">
              Success
            </span>
            <span v-else-if="row.item.status == 2" class="badge badge-danger">
              Processing
            </span>
            <span v-else-if="row.item.status == 11" class="badge badge-danger">
              Error
            </span>
            <span v-else class="badge badge-info">
              Waiting for processing
            </span>
          </div>
        </template>
        <template #cell(file_sizes)="row">
          {{ fileSizeFormat(row.item.file_size) }}
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-dark my-2">
            <b-spinner class="align-middle" variant="dark" type="grow" label="Loading ..">
            </b-spinner>
            Loading ..
          </div>
        </template>
        <template #cell(show_details)="row">
          <b-button size="sm" @click="row.toggleDetails" class="mr-2 btn-success">
            {{ row.detailsShowing ? 'Hide' : 'Show' }}
          </b-button>
        </template>
        <template #row-details="row">
          <div>
            <table>
              <thead>
                <tr>
                  <th>File Size</th>
                  <th>Error</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody v-for="line in row" :key="line.id">
                <tr v-if="line.id == row.item.id" class="text-left">
                  <td>{{ fileSizeFormat(line.file_size) }}</td>
                  <td>{{ line.error }}</td>
                  <td>{{ formatDateItems(line.created) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </b-table>
      <b-row class="justify-content-start ml-1">
        <b-col lg="3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
            @change="onClickPage"
            />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
import constant from "../../store/constant";

const statusOptions = Object.entries(constant.UPLOAD_PROCESS_STATUS)
  .map(item => ({ value: item[1], text: item[0] }));
export default {
  name: "MonitoringItemUploadAudio",
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      filter: null,
      isLoadingSearch: false,
      items_search: [],
      fields: [
        { key: 'No', label: 'No' },
        {
          key: 'name',
          label: 'Name',
          thStyle: { width: "50%" },
        },
        {
          key: 'file_name',
          label: 'File Name',
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'release_schedule', label: 'Release Schedule', formatter: function (value) {
            return moment(value).toString();
          },
          class: 'text-nowrap',
        },
        { key: 'pic_name'},
        {
          key: 'start_process',
          label: 'Start Process Time',
          class: 'text-nowrap'
        },
        {
          key: 'end_process',
          label: 'End Process Time',
          class: 'text-nowrap'
        },
        {
          key: 'show_details',
          label: 'Show Details',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      typing: false,
      status: null,
      statusOptions: [
        { value: null, text: "--Status--" },
        ...statusOptions,
      ]
    };
  },
  computed: {
    ...mapGetters("MonitoringItemUpload", { items: "fetchAudio" }),
    ...mapState({
      isLoading: state => state.MonitoringItemUpload.isLoading,
      isError: state => state.MonitoringItemUpload.isError,
      totalRows: state => state.MonitoringItemUpload.totalRows,
      items: state => state.MonitoringItemUpload.items,
    })
  },
  mounted() {
    this.fetchItemProcess();
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Items Upload Progress | Content Management System Ebooks Gramedia.com';
      }
    },
  },
  methods: {
    ...mapActions('MonitoringItemUpload', [
      'fetchAudio',
      'searchAudio',
      'updateStatus',
    ]),
    onClickPage(page) {
      this.currentPage = page;
      this.onSearchItem();
    },
    formatDateItems(value) {
      if(!value) {
        return 'No Created Date';
      }
      return moment(value).toString();
    },
    fetchItemProcess() {
      this.filter = null;
      let payload = {
        status: this.status,
        page: this.currentPage,
        limit: this.perPage
      };

      this.fetchAudio(payload);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(tgl) {
      return moment(tgl).toString();
    },
    onSearchItem() {
      this.status = null;
      const payload = {
        limit: this.perPage,
        page: this.currentPage,
      }
      if (this.filter) { // for search item
        payload.name = this.filter,
        this.typing = true;
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.typing = false;
          this.filter = payload.name;
          this.searchAudio(payload);
        }, 600);
      } else {
        this.fetchAudio(payload);
      }
      
    },
    reloadBtn() {
      this.fetchItemProcess();
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return "Type Here";
      }
    },
    formatPrice(value, currency) {
      if (value) {
        let formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return "0";
    },
    fileSizeFormat(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return (
        parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      );
    },
  },
};
</script>

<style scoped>

</style>
